$reset-button-bg: #808080

@import bol_form
@import form_dropdown_search

.form-sub-header
  border-bottom: 1px solid #999999
  margin-bottom: 15px

.gen-form
  .form-floating
    margin-bottom: 1rem

  .form-control:valid:not(.is-invalid)
    border-color: #ced4da
    background-image: none !important

  .form-select:valid
    border-color: #ced4da
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important

  .form-control:valid:focus, .form-select:valid:focus
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

  .custom-control-label
    color: inherit !important

  .fa-minus
    cursor: pointer
    filter: invert(24%) sepia(0%) saturate(0%) hue-rotate(233deg) brightness(95%) contrast(92%)

  .fa-plus
    cursor: pointer
    margin-left: 10px
    filter: invert(10%) sepia(67%) saturate(2134%) hue-rotate(228deg) brightness(90%) contrast(90%)

  .currency-input:disabled
    background-color: #e9ecef


  .currency-input:focus
    outline: none
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

.form-document-container
  .form-document-list-group-item
    padding: 0

  .form-document-list-group-item:not(:only-child):first-child
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0

  .form-document-list-group-item:not(:only-child):last-child
    border-top-left-radius: 0
    border-top-right-radius: 0

  .form-document-actions
    border-top-left-radius: inherit
    border-top-right-radius: inherit
    border-bottom-left-radius: inherit
    border-bottom-right-radius: inherit

    .list-group-item
      display: flex
      justify-content: center
      background-color: var(--bs-gray-200)
      transition: color .15s

      @media (min-width: map-get($grid-breakpoints, md))
        border-top: 0
        border-bottom: 0

      @media (max-width: map-get($grid-breakpoints, md))
        border-left: 0
        border-right: 0

      .spinner-border
        color: $base-sidenav-background !important
        border-right: 0.25rem solid #f8f9fa

      &:hover, &:focus
        background-color: $base-sidenav-background
        color: #fff
        border-color: #ced4da

        .spinner-border
          color: #f8f9fa !important
          border-right: 0.25rem solid $base-sidenav-background

    .list-group-item:first-child
      border-top-left-radius: inherit

      @media (min-width: map-get($grid-breakpoints, md))
        border-bottom-left-radius: inherit
        border-left: 0

      @media (max-width: map-get($grid-breakpoints, md))
        border-top: 0

    .list-group-item:last-child
      border-bottom-right-radius: inherit

      @media (min-width: map-get($grid-breakpoints, md))
        border-top-right-radius: inherit
        border-right: 0

      @media (max-width: map-get($grid-breakpoints, md))
        border-bottom: 0

  .form-control
    color: #fff
    background-color: $base-sidenav-background
    border: 0
    border-radius: inherit

    .badge
      color: $app-text-color
      padding-top: 0.4rem
      padding-bottom: 0.4rem

    .badge.loading
      padding-top: 0.25em
      padding-bottom: 0.25em
