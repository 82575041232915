#esign-modal
  .modal-body
    height: 80vh

    iframe
      border: none
      border-radius: 0.25rem

.esigners-status-popover
    max-width: none !important
