$sidenav-text-color: #d9d9d9

.main-nav
  color: $sidenav-text-color

  .nav-icon
    height: 2rem
    filter: invert(75%) sepia(49%) saturate(0%) hue-rotate(265deg) brightness(89%) contrast(84%)

  #hamburger-icon
    filter: invert(10%) sepia(67%) saturate(2134%) hue-rotate(228deg) brightness(90%) contrast(90%)

  .nav-link, .nav-accordion
    height: 3rem
    padding-top: .25rem
    padding-bottom: .25rem
    align-items: center !important
    padding-left: .35rem
    padding-right: .35rem
    cursor: pointer
    transition: none

  a
    color: inherit !important
    text-decoration: none

  .accordion-header
    align-items: center

  .accordion-body
    font-size: 0.8rem

  #logout-container
    color: $sidenav-text-color
    border-top: 1px solid $midnight-navy

#side-nav
  background-color: $base-sidenav-background
  height: 100%
  z-index: 2

  @media (min-width: map-get($grid-breakpoints, xxs))
    width: 3.5rem

  @media (min-width: map-get($grid-breakpoints, md))
    width: 3rem

  #side-nav-inner
    background-color: inherit
    min-height: 100%
    width: 18rem
    overflow: hidden
    transform: translateX(-15rem)
    transition: transform .25s

  #side-nav-inner:hover
    transform: translateX(0)
    transition-delay: .1s

  #side-nav-content
    transform: translateX(15rem)
    transition: transform .25s
    overflow-y: auto

  #side-nav-content::-webkit-scrollbar
    background-color: #a6a6a6
    width: 6px

  #side-nav-content::-webkit-scrollbar-thumb
    background-color: $midnight-navy
    border: 2px solid $midnight-navy
    border-radius: 8px

  .nav-link, .nav-accordion
    p
      opacity: 0

  .header
    padding-top: 5px
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    padding-left: 0.7rem

    div.d-flex
      width: 35px

    p
      font-size: 0.95rem
      opacity: 0

  #header-border-bottom
    opacity: 0

  .accordion-body
    opacity: 0
    position: absolute

    span.active
      background-color: $midnight-navy

    div:first-child
      margin-top: 0.5rem !important

    div:last-child
      margin-bottom: 0.5rem !important

    div:hover
      font-weight: 600

  #logout-container
    opacity: 0
    transition: opacity 0

  // Styles applied when user hovers nav bar (expanded nav)
  #side-nav-content:hover
    transform: translateX(0)
    transition-delay: .1s
    background-color: $base-sidenav-background

    #header-border-bottom
      border-bottom: 1px solid $midnight-navy
      opacity: 1
      transition: opacity 0.8s

    .nav-link, .nav-accordion, .header
      p
        opacity: 1

    .accordion-body
      opacity: 1
      position: static

    #logout-container
      opacity: 1
      transition: opacity 0.8s

  .nav-link.active, .nav-link:hover, .nav-accordion.active, .nav-accordion:hover
    background-color: $midnight-navy

#top-nav
  height: 3rem
  color: $sidenav-text-color
  background-color: $base-sidenav-background

#top-nav-modal
  .modal-content
    color: $sidenav-text-color
    background-color: $base-sidenav-background

  #top-nav-close
    width: 4rem
    height: 4rem
    background-color: #fff
    border-radius: 50%
    position: absolute
    margin-top: 15px
    margin-right: 15px
    top: 0
    right: 0

  #logout-container
    margin-left: -15px
    margin-right: -15px
    margin-bottom: -15px

    p
      padding-left: 15px

    img
      margin-right: 10px
