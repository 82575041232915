.refinable-attr-icon
  filter: invert(10%) sepia(67%) saturate(2134%) hue-rotate(228deg) brightness(90%) contrast(90%)

.refinable-title-pill
  color: #fff
  background-color: $base-sidenav-background !important

.filters-container
  .applied-filter-count
    color: $base-sidenav-background

  .filterable-values-container
    .filtered
      color: #fff
      border: 2px solid
      border-color: #6c757d
      background-color: #6c757d !important

    .unfiltered
      color: $app-text-color
      border: 2px solid
      border-color: #6c757d
      background-color: #fff !important

.gen-cards-list
  .card
    .card-header
      background-color: $table-header-gray
      color: #fff
      font-weight: 600
