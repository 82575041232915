#bol-history-table

.bol-history-card-container
  .card
    margin-bottom: 1rem

    .card-header
      color: #fff
      background-color: $table-header-gray


