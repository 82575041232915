.interactable-table
  display: grid
  grid-auto-rows: minmax(3rem, auto)
  overflow-x: auto

  .table-data, .column-filters
    transition: transform 0.2s cubic-bezier(.2,0,0,1)

  .table-data
    padding: 0.75rem
    padding-left: 0.5rem

  .table-data.striped
    background-color: rgba(0, 0, 0, 0.05)

  .table-header
    border-color: #b6bfc8
    border-style: solid
    border-width: 1px 0 1px 0
    font-weight: bold
    cursor: move

    .column-filter-icon
      cursor: pointer
      filter: invert(10%) sepia(67%) saturate(2134%) hue-rotate(228deg) brightness(90%) contrast(90%)

    .sortable
      color: $base-sidenav-background
      cursor: pointer

      &:hover
        transform: scale(1.05)

  .dropdown
    .list-unstyled
      margin-bottom: 0px

      .form-group
        margin-bottom: 0px

      a > div
        cursor: pointer

        div
          input, label
            cursor: inherit

      .dropdown-item:active
        background-color: $base-sidenav-background
        border-color: $base-sidenav-background

  .select-all-check
    display: inline
    margin-right: 0.5rem

  .table-check
    input
      margin-top: 0.15rem
      margin-bottom: 0.15rem

.interactable-table::-webkit-scrollbar
    background-color: #a6a6a6
    height: 0.5rem
    border-radius: 0.25rem

.interactable-table::-webkit-scrollbar-thumb
  background-color: $midnight-navy
  border: 2px solid $midnight-navy
  border-radius: 0.25rem

.no-table-results
  background-color: #bfbfbf

  svg
    filter: invert(10%) sepia(67%) saturate(2134%) hue-rotate(228deg) brightness(90%) contrast(90%)
