#header-errors
  margin-left: -0.75rem
  margin-right: -0.75rem

  ul
    margin-bottom: 0
    padding-inline-start: inherit

#header-errors.errors-inside-container
  margin-top: -15px
