.constraint-container
  border: 1px solid rgba(115, 115, 115, 0.5)
  border-radius: .25rem

  #amount
    border-top-right-radius: 0.25rem
    border-bottom-right-radius: 0.25rem

  .save-insurance-constraint
    height: calc(1.5em + 0.75rem + 2px)

.constraint-container.inactive
  opacity: .5
