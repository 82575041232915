.dropdown-search-hits
    margin-top: -1rem
    position: absolute
    width: 100%
    z-index: 2

    .list-group-item
        //padding: 0.4rem 1.25rem

    .list-group-item:focus
        outline: none
        background-color: #e7f1ff

    .list-group-item:hover
        background-color: #e7f1ff

