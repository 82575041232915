#tracking-legend-table
  margin-top: 2.75rem

  #tracking-legend-table-data
    max-height: 37.5rem
    overflow-y: auto
    border-bottom-left-radius: 0.25rem
    border-bottom-right-radius: 0.25rem

    @media (max-width: map-get($grid-breakpoints, md))
      max-height: 15rem

    .list-group-item:first-child
      border-top: 0
