.carrier-coverage, .carrier-contact
  .accordion-button
    padding-left: 0
    background-color: $app-background

  .accordion-button:not(.collapsed)::after
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")

#remove-carrier-modal
  form
    width: 100%

.carrier-tabs
  border-bottom: 1px solid #999999
  margin-bottom: 15px

  .nav-item:first-child
    position: absolute
    font-size: 1.0625rem

  .nav-item:nth-child(2)
    margin-left: auto

  .nav-link
    color: $base-sidenav-background

  .nav-link.active
    background-color: $app-background
    border-color: #999999 #999999 $app-background

  .nav-link.disabled
    color: $app-text-color
