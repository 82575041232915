.company-settings
  .settings-nav
    margin-bottom: 15px

    .list-group
      a
        text-decoration: none

      .list-group-item
        border-width: 0 0 1px
        padding-top: 0.5rem
        padding-bottom: 0.5rem

  @media (min-width: 1200px)
    .settings-nav
      margin-top: -15px
      margin-bottom: -15px
      border-right: 1px solid #dfdfdf

      .list-group
        height: 100vh
        padding-top: 15px

    .settings-body
      padding-left: 15px

  .form-document-container
    padding-top: 0px
    margin-bottom: 1rem

    .custom-file-label
      background-color: #fff
      color: $app-text-color
