#load-posting-editor
  border: 2px solid #ced4da
  border-radius: 0.25rem

  .sbs-datetime
    width: 48%

  #lane-rate-cards
    &[class*="col-"]:first-child
      margin-top: 0

  #post-start-tooltip
    z-index: 1000
