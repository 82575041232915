$font-size-base: 0.85rem

$utilities: ('width': (property: width, class: w, responsive: true, values: (25: 25%, 33: 33%, 50: 50%, 66: 66%, 75: 75%, 100: 100%, auto: auto,),))

@import "~bootstrap/scss/bootstrap"

@mixin linear-gradient($direction, $color-stops...)
  background: nth(nth($color-stops, 1), 1)
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops)
  background: linear-gradient($direction, $color-stops)

$app-background: #fff
$app-text-color: #404040
$base-sidenav-background: #4545a1
$midnight-navy: #2e2e6b
$table-header-gray: #737373

html, body, #root
  height: 100%
  background-color: $app-background

body
  font-family: sans-serif
  margin: 0
  padding: 0

#app-inner
  height: 100%
  flex-wrap: nowrap

  @include media-breakpoint-up(sm)
    display: flex

#tc-container
  overflow-y: scroll
  overflow-x: hidden
  padding-top: 15px
  padding-bottom: 15px
  color: $app-text-color
  z-index: 1 // Put behind sidebar nav tooltips

  @include media-breakpoint-down(sm)
    overflow-y: hidden

.thead-gray th
  color: #fff
  background-color: $table-header-gray
  border-color: #595959

.th-gray-handle
  padding-left: 0.25rem !important
  border-left: 1rem $base-sidenav-background solid

.outline-indigo
  border-color: $base-sidenav-background
  background-color: #fff
  color: $base-sidenav-background
  transition: color .15s

  &:hover
    background-color: $base-sidenav-background
    border-color: $base-sidenav-background

  &:focus
    background-color: $base-sidenav-background
    color: #fff
    border-color: $base-sidenav-background
    box-shadow: 0 0 0 0.25rem rgba(69,69,161,.5)

  &:active
    box-shadow: 0 0 0 0.25rem rgba(69,69,161,.5) !important

.indigo-button
  background-color: $base-sidenav-background
  border-color: $base-sidenav-background

  &:hover
    background-color: #3d3d8f
    border-color: $base-sidenav-background

  &:focus
    background-color: #3d3d8f
    border-color: #3d3d8f
    box-shadow: 0 0 0 0.25rem rgba(69,69,161,.5)

  &:active
    background-color: #3d3d8f !important
    border-color: #3d3d8f !important
    box-shadow: 0 0 0 0.25rem rgba(69,69,161,.5) !important

.indigo-button:disabled
  background-color: $base-sidenav-background
  border: none

.spinner-indigo
  color: $base-sidenav-background !important

.form-check-input
  &:checked
    background-color: $base-sidenav-background !important
    border-color: $base-sidenav-background !important

  &:focus
    box-shadow: none !important

.form-switch
  .form-check-input
    &:not(:checked)
      border-color: #00000040
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300000040'/%3e%3c/svg%3e")

  .form-check-label
    color: $app-text-color !important

.accordion-button
  &:focus
    box-shadow: none

  &:not(.collapsed)
    color: $app-text-color
    box-shadow: inset 0 -1px 0 #00000021

.button-xs
  padding-top: 0rem
  padding-bottom: 0rem

.accordion-arrow
  transition: .35s ease

.accordion-arrow.rotated
  transform: rotate(90deg)

.cursor-pointer
  cursor: pointer !important

.error-red
  filter: invert(34%) sepia(33%) saturate(4992%) hue-rotate(334deg) brightness(88%) contrast(96%)

.success-green
  filter: invert(39%) sepia(10%) saturate(3771%) hue-rotate(102deg) brightness(101%) contrast(80%)

.warning-yellow
  filter: invert(90%) sepia(21%) saturate(6383%) hue-rotate(351deg) brightness(99%) contrast(103%)

.modal-90w
  width: 90%
  max-width: none

.nav-pills .nav-link
  color: $base-sidenav-background

.nav-pills .nav-item .active
  color: #fff
  background-color: $base-sidenav-background

.nav-tabs
  .nav-link
    color: $base-sidenav-background

  .nav-link.active
    color: #495057

.gm-style
  div
    border: none !important

.tc-scroll::-webkit-scrollbar
    background-color: #eaeaea
    width: 6px
    border-radius: 8px

.tc-scroll::-webkit-scrollbar-thumb
  background-color: #a4a4a4
  border: 2px solid #a4a4a4
  border-radius: 8px

.tooltip
  --bs-tooltip-bg: #4545a1

  .tooltip-inner
    font-size: 0.8rem

.rounded-top-left
  border-top-left-radius: 0.25rem

.rounded-bottom-left
  border-bottom-left-radius: 0.25rem

.rounded-top-right
  border-top-right-radius: 0.25rem

.rounded-bottom-right
  border-bottom-right-radius: 0.25rem

$grid-breakpoints: (xxxs: 0, xxs: 320px, xs: 568px, sm: 667px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px, xxxl: 1920px)
